// Behaves like an enum
export const STEPS = {
    SERVICE_PROCESS: 'SERVICE_PROCESS',
    GENERATION_PROCESS: 'GENERATION_PROCESS',
    CONNECTION_PROCESS: 'CONNECTION_PROCESS',
    TRANSPOSITION_PROCESS: 'TRANSPOSITION_PROCESS',
    DOCUMENT_UPLOAD_PROCESS: 'DOCUMENT_UPLOAD_PROCESS',
    CONNECTION_ASSURANCE_PROCESS: 'CONNECTION_ASSURANCE_PROCESS',
    NEXT_STEPS_PROCESS: 'NEXT_STEPS_PROCESS',
} as const;

// Union type of the values of all fields of the constant STEPS object
export type StepsValue = (typeof STEPS)[keyof typeof STEPS];

export const ALL_STEPS: StepsValue[] = [
    STEPS.SERVICE_PROCESS,
    STEPS.GENERATION_PROCESS,
    STEPS.CONNECTION_PROCESS,
    STEPS.TRANSPOSITION_PROCESS,
    STEPS.DOCUMENT_UPLOAD_PROCESS,
    STEPS.CONNECTION_ASSURANCE_PROCESS,
    STEPS.NEXT_STEPS_PROCESS,
];

/* Behaves like an enum and holds all subprocesses of the request form.
 * Any process that is finalized by a click of the 'Weiter' button is considered a subprocess.
 * Using the map below, the indices of the step and the index of the subprocess within the step can be retrieved. */
export const SUBPROCESS = {
    SERVICE_AND_CAPACITY: 'SERVICE_AND_CAPACITY',
    SERVICE_LOCATION_DATA: 'SERVICE_LOCATION_DATA',
    GENERATION_GENERATOR: 'GENERATION_GENERATOR',
    GENERATION_STORAGE: 'GENERATION_STORAGE',
    GENERATION_PLANT_COMPONENTS_SMALLEST_PLANT: 'GENERATION_PLANT_COMPONENTS_SMALLEST_PLANT',
    GENERATION_PLANT_COMPONENTS_GENERATOR: 'GENERATION_PLANT_COMPONENTS_GENERATOR',
    GENERATION_PLANT_COMPONENTS_STORAGE: 'GENERATION_PLANT_COMPONENTS_STORAGE',
    GENERATION_PLANT_COMPONENTS_OVERVIEW: 'GENERATION_PLANT_COMPONENTS_OVERVIEW',
    GENERATION_PLANT_OPERATOR: 'GENERATION_PLANT_OPERATOR',
    CONNECTION_PLAN: 'CONNECTION_PLAN',
    CONNECTION_ELECTRICITY_METER: 'CONNECTION_ELECTRICITY_METER',
    TRANSPOSITION_INSTALLER_SELECTION: 'TRANSPOSITION_INSTALLER_SELECTION',
    TRANSPOSITION_PLANT_BUILDER: 'TRANSPOSITION_PLANT_BUILDER',
    TRANSPOSITION_TECHNICAL_OPERATIONS_MANAGER: 'TRANSPOSITION_TECHNICAL_OPERATIONS_MANAGER',
    DOCUMENT_UPLOAD: 'DOCUMENT_UPLOAD',
    CONNECTION_ASSURANCE_OVERVIEW: 'CONNECTION_ASSURANCE_OVERVIEW',
    CONNECTION_ASSURANCE_CONFIRMATION: 'CONNECTION_ASSURANCE_CONFIRMATION',
    NEXT_STEPS: 'NEXT_STEPS',
} as const;

// Union type of the values of all fields of the constant SUBPROCESS object
export type SubprocessValue = (typeof SUBPROCESS)[keyof typeof SUBPROCESS];

/* Used to retrieve the step and subprocess indices of subprocesses.
 * This can be used, e.g., to conditionally hide steps in the wizard.
 * The first element of the list identifies the step and the second one identifies that of the given subprocess
 * within it.
 * Example: 'Service' is the first step in the request form and the location data is asked for in the second subprocess
 *          within it. Using 0-based indexing, SERVICE_LOCATION_DATA thus maps to [0,1].
 *
 *          The syntax for accessing the map would be SUBPROCESS_INDEX_MAPPING[SUBPROCESS.SERVICE_LOCATION_DATA]
 */
export const SUBPROCESS_INDEX_MAPPING: { [key in SubprocessValue]: [number, number] } = {
    SERVICE_AND_CAPACITY: [0, 0],
    SERVICE_LOCATION_DATA: [0, 1],
    GENERATION_GENERATOR: [1, 0],
    GENERATION_STORAGE: [1, 1],
    GENERATION_PLANT_COMPONENTS_SMALLEST_PLANT: [1, 2],
    GENERATION_PLANT_COMPONENTS_GENERATOR: [1, 3],
    GENERATION_PLANT_COMPONENTS_STORAGE: [1, 4],
    GENERATION_PLANT_COMPONENTS_OVERVIEW: [1, 5],
    GENERATION_PLANT_OPERATOR: [1, 6],
    CONNECTION_PLAN: [2, 0],
    CONNECTION_ELECTRICITY_METER: [2, 1],
    TRANSPOSITION_INSTALLER_SELECTION: [3, 0],
    TRANSPOSITION_PLANT_BUILDER: [3, 1],
    TRANSPOSITION_TECHNICAL_OPERATIONS_MANAGER: [3, 2],
    DOCUMENT_UPLOAD: [4, 0],
    CONNECTION_ASSURANCE_OVERVIEW: [5, 0],
    CONNECTION_ASSURANCE_CONFIRMATION: [5, 1],
    NEXT_STEPS: [6, 0],
};
