import appConfig from '@/app.config';
import { ApiParams } from '@/hooks/useApi';
import { Context } from '@/wizard';
import { AxiosResponse } from 'axios';

export const getDraftRequest = async ({
    customerId,
    axios,
    params,
}: ApiParams<{ requestId: string }>): Promise<Partial<Context>> => {
    const response: AxiosResponse<Partial<Context>> = await axios.get(
        `${appConfig.services.distributedGenerationApi}/account/${customerId}/distributed-generation-request/${params.requestId}/draft`,
    );

    return response.data;
};
