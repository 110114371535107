import { SERVICE_AND_CAPACITY } from '@/routes';
import { ALL_STEPS } from '@/wizard/constants';
import { Context } from '@/wizard/context';
import { requestFormVersion } from '@/wizard/version';

export const contextMapper = (context: Partial<Context>): Partial<Context> => ({
    ...context,
    meta: {
        ...context.meta,
        furthestStep: { furthestProcess: ALL_STEPS[0], furthestSubprocess: 1 },
        requestFormVersion,
        path: SERVICE_AND_CAPACITY,
    },
});
