export const DASHBOARD = '/uebersicht';

export const APP = '/einspeisung';
export const ROOT = `${APP}/antragsstrecke`;

export const START = `${ROOT}/startseite`;

export const MAINTENANCE = `${ROOT}/wartung`;

export const LANDING_PAGE = `${ROOT}/entwurf-antrag/:requestId`;

export const SERVICE = `${ROOT}/service`;
export const SERVICE_AND_CAPACITY = `${SERVICE}/service-und-leistung`;
export const SERVICE_LOCATION_DATA = `${SERVICE}/standortdaten`;
export const RESPONSIBILITY_AND_AVAILABILITY = `${SERVICE}/zustaendigkeit-und-verfuegbarkeit`;

export const GENERATION = `${ROOT}/erzeugungsanlage`;
export const GENERATION_GENERATOR = `${GENERATION}/erzeugung`;
export const GENERATION_STORAGE = `${GENERATION}/speicherung`;
export const GENERATION_PLANT_COMPONENTS = `${GENERATION}/anlagekomponenten`;
export const GENERATION_PLANT_COMPONENTS_SMALLEST_PLANT = `${GENERATION_PLANT_COMPONENTS}/balkonkraftwerk`;
export const GENERATION_PLANT_COMPONENTS_GENERATOR = `${GENERATION_PLANT_COMPONENTS}/erzeugungsanlage`;
export const GENERATION_PLANT_COMPONENTS_STORAGE = `${GENERATION_PLANT_COMPONENTS}/energiespeicher`;
export const GENERATION_PLANT_COMPONENTS_OVERVIEW = `${GENERATION_PLANT_COMPONENTS}/uebersicht`;
export const GENERATION_PLANT_OPERATOR = `${GENERATION}/anlagenbetreiber`;

export const CONNECTION = `${ROOT}/anschluss`;
export const CONNECTION_PLAN = `${CONNECTION}/plan`;
export const CONNECTION_ELECTRICITY_METER = `${CONNECTION}/stromzaehler`;

export const TRANSPOSITION = `${ROOT}/umsetzung`;
export const TRANSPOSITION_INSTALLER_SELECTION = `${TRANSPOSITION}/installateur`;
export const TRANSPOSITION_PLANT_BUILDER = `${TRANSPOSITION}/anlagenerrichter`;
export const TRANSPOSITION_TECHNICAL_OPERATIONS_MANAGER = `${TRANSPOSITION}/technischer-betriebsfuehrer`;

export const DOCUMENT_UPLOAD = `${ROOT}/dokumenten-upload`;

export const CONNECTION_ASSURANCE = `${ROOT}/anschlusszusage`;

export const CONNECTION_ASSURANCE_OVERVIEW = `${CONNECTION_ASSURANCE}/antragsuebersicht`;
export const CONNECTION_ASSURANCE_CONFIRMATION = `${CONNECTION_ASSURANCE}/anschlusszusage`;

export const NEXT_STEPS = `${ROOT}/naechste-schritte`;

export const CONNECTION_ASSURANCE_CONFIRMATION_ACCEPT = `${APP}/anschlusszusage/:id`;

export const REQUEST_ROOT = `${APP}/antrag/:requestId`;
export const REQUEST_DETAILS_VIEW = `${REQUEST_ROOT}/details`;
export const REQUEST_DOCUMENTS = `${REQUEST_ROOT}/dokumente`;
export const REQUEST_DOCUMENT_UPLOAD = `${REQUEST_ROOT}/dokumente/dokumenten-upload`;
export const SELECT_INSTALLER = `${REQUEST_ROOT}/:processCommunicationId/installateur-auswahl`;
export const REQUEST_UPDATE_TECHNICAL_OPERATIONS_MANAGER = `${REQUEST_ROOT}/technischer-betriebsfuehrer-auswahl`;
export const REQUEST_UPDATE_PLANT_OPERATOR = `${REQUEST_ROOT}/:processCommunicationId/anlagenbetreiber-aendern`;

export const CCD_APP = '/steuerbare-verbrauchseinrichtung';

export const CCD_LANDING_PAGE = `${CCD_APP}/antragsstrecke/externer-antrag/:processCommunicationId`;
